import clsx from 'clsx'
import { forwardRef } from 'react'
import { useBoolean } from 'usehooks-ts'

interface LiteYouTubeProps {
  id: string
  title: string
  isEnabled: boolean
  enable: () => void
  playtime?: number
}

const playerParams = new URLSearchParams()
playerParams.set('autoplay', '1')
playerParams.set('state', '1')
playerParams.set('controls', '1')
playerParams.set('color', 'white')
playerParams.set('modestbranding', '1')
playerParams.set('rel', '0')
playerParams.set('enablejsapi', '1')

const baseUrl = 'https://www.youtube-nocookie.com'

/**
 * Heavily inspired by https://github.com/ibrahimcesar/react-lite-youtube-embed/tree/main
 */
export const LiteYouTubeEmbed = forwardRef(
  (
    { id, isEnabled, enable, playtime = 0, title }: LiteYouTubeProps,
    ref: React.Ref<HTMLIFrameElement>,
  ) => {
    const { value: preconnect, setTrue: warmConnections } = useBoolean(false)

    const videoId = encodeURIComponent(id)
    const posterUrl = `https://i.ytimg.com/vi_webp/${videoId}/maxresdefault.webp`
    const iframeSrc = `${baseUrl}/embed/${videoId}?start=${playtime}&${playerParams.toString()}`

    const iframe = (
      <iframe
        ref={ref}
        title={title}
        width="560"
        height="315"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        src={iframeSrc}
      />
    )

    return (
      <>
        <link rel="preload" href={posterUrl} as="image" />
        {preconnect && (
          <>
            <link rel="preconnect" href={baseUrl} />
            <link rel="preconnect" href="https://www.google.com" />
          </>
        )}
        <div
          onPointerOver={warmConnections}
          onClick={enable}
          className={clsx('yt-lite', isEnabled && 'lyt-activated')}
          data-title={title}
          style={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            '--aspect-ratio': `calc(9 / 16 * 100)%`,
            backgroundImage: `url(${posterUrl})`,
          }}
        >
          <button type="button" className="lty-playbtn" aria-label={title} />
          {isEnabled && iframe}
        </div>
      </>
    )
  },
)

LiteYouTubeEmbed.displayName = 'LiteYouTubeEmbed'
